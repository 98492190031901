import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  Button,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import Login from './Login';

const API_KEY = 'YmRhYjZlMzMtMjc4YS00MDE1LWJmYmUtYzFmOGUyYTExNTcy';
const WORKSPACE_ID = '65e189c947cc8425e9a2a5d0';
const PROJECT_ID = '66b9fa79ad40c41cd3010c89';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timeEntries, setTimeEntries] = useState([]);
  const [totalTime, setTotalTime] = useState(0);
  const [startDate, setStartDate] = useState(new Date('2024-08-01'));
  const [endDate, setEndDate] = useState(new Date('2025-08-01'));

  const fetchTimeEntries = async () => {
    setLoading(true);
    console.log('Fetching time entries...');
    console.log('Start Date:', startDate.toISOString());
    console.log('End Date:', endDate.toISOString());
    
    
    
    try {
      const response = await axios.post(
        `https://reports.api.clockify.me/v1/workspaces/${WORKSPACE_ID}/reports/detailed`,
        {
          dateRangeEnd: endDate.toISOString(),
          dateRangeStart: startDate.toISOString(),
          detailedFilter: {
            page: 1,
            pageSize: 200,
          },
          projects: {
            contains: "CONTAINS",
            ids: [PROJECT_ID],
            status: "ACTIVE",
          },
        },
        {
          headers: {
            'X-Api-Key': API_KEY,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Detailed Report:', response.data);
      

      const sortedEntries = response.data.timeentries.sort((a, b) => {
        return new Date(a.timeInterval.start) - new Date(b.timeInterval.start);
      });

      setTimeEntries(sortedEntries);
      setTotalTime(sortedEntries.reduce((acc, entry) => acc + entry.timeInterval.duration, 0));
    } catch (error) {
      console.error('Error fetching detailed report:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (loggedIn) {
      fetchTimeEntries();
    }
  }, [loggedIn]);

  const handleFilter = () => {
    fetchTimeEntries();
  };

  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  if (!loggedIn) {
    return <Login onLogin={setLoggedIn} />;
  }

  if (loading) {
    return (
      <Container style={{ textAlign: 'center', marginTop: '50px' }}>
        <CircularProgress />
        <Typography variant="h6">Loading Time Entries...</Typography>
      </Container>
    );
  }

  return (
    <Container style={{ marginTop: '50px' }}>
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography variant="h4" gutterBottom>
          Detailed Report for Project
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(date) => setStartDate(date)}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(date) => setEndDate(date)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFilter}
          style={{ marginTop: '20px' }}
        >
          Filter
        </Button>
        <List style={{ marginTop: '20px' }}>
          {timeEntries.length > 0 ? (
            timeEntries.map((entry) => (
              <div key={entry._id}>
                <ListItem>
                  <ListItemText
                    primary={`User: ${entry.userName}`}
                    secondary={`Description: ${entry.description || 'No Description'} | Duration: ${formatDuration(entry.timeInterval.duration)} | Start: ${new Date(entry.timeInterval.start).toLocaleString()} | End: ${entry.timeInterval.end ? new Date(entry.timeInterval.end).toLocaleString() : 'In Progress'}`}
                  />
                </ListItem>
                <Divider />
              </div>
            ))
          ) : (
            <Typography variant="body1">No time entries found for this project.</Typography>
          )}
        </List>
        <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
        <Typography variant="h6" gutterBottom>
          Summary
        </Typography>
        <Typography variant="body1">
          Total Time Logged: {formatDuration(totalTime)}
        </Typography>
      </Paper>
    </Container>
  );
}

export default App;
